<template>
  <div>

    <v-dialog v-model='isOpen' max-width="450" scrollable>
      <v-card :loading='loading' :disabled='loading'>
        <v-card-title class='primary white--text'>
          Package Templates
          <v-spacer />
          <v-btn fab small text dark @click='newPackage'><v-icon>mdi-plus</v-icon></v-btn>
        </v-card-title>
        <v-card-text style='' class='px-0'>
          <v-list dense ref='sortArea'>
            <v-list-item v-for='p in packages' :key='p.xid' :data-id="p.xid">
              <v-list-item-avatar class='dragMe ma-0'>
                <v-icon class=''>mdi-drag</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{p.label}}</v-list-item-title>
                <v-list-item-subtitle v-if='p.courier'>{{p.courier.label}}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action class='ma-0'>
                <v-menu>
                  <template #activator="{on,attrs}">
                    <v-btn fab x-small text class='primary--text' v-on='on' v-bind="attrs">
                      <v-icon>mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item link @click='clickEditPackage(p)'><v-icon class='primary--text mr-4'>mdi-pencil</v-icon>Edit</v-list-item>
                    <v-divider></v-divider>
                    <v-list-item link @click='deletePackage(p)'><v-icon class='error--text mr-4'>mdi-trash-can</v-icon>Remove</v-list-item>
                  </v-list>
                </v-menu>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider />
        <v-card-actions class='grey lighten-4'>
          <v-spacer />
          <v-btn class='secondary' @click='close'>Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model='editPackage.isOpen' max-width="600" scrollable persistent>
      <v-card :loading='loading' :disabled='editPackage.loading' v-if='editPackage.isOpen'>
        <v-card-title class='primary white--text'>
          Edit Package Template
        </v-card-title>
        <v-card-text style='max-hxeight:100vh;heifght:auto;' class='pt-4'>

          <!-- Label -->
          <v-row class="">
            <v-col cols="6">
              <v-text-field outlined dense hide-details label="Template Label" v-model='editPackage.data.label' />
            </v-col>
            <v-col cols="12">
              <v-text-field outlined dense hide-details label="Description of Contents" v-model='editPackage.data.description' />
            </v-col>
            <v-col cols="3">
              <v-text-field outlined dense hide-details label="Length" suffix="cm" v-model='editPackage.data.length' />
            </v-col>
            <v-col cols="3">
              <v-text-field outlined dense hide-details label="Width" suffix="cm" v-model='editPackage.data.width' />
            </v-col>
            <v-col cols="3">
              <v-text-field outlined dense hide-details label="Height" suffix="cm" v-model='editPackage.data.height' />
            </v-col>
            <v-col cols="3">
              <v-text-field outlined dense hide-details label="Weight" suffix="kg" v-model='editPackage.data.weight' />
            </v-col>
            <v-col cols="6">
              <LazyDropdown url='dispatch/couriers' outlined dense label='Courier' itemText='label' itemValue='xid' v-model='editPackage.data.courier' />
            </v-col>
            <v-col cols="6">
              <LazyDropdown outlined dense label='Service' itemText='label' itemValue='xid' />
            </v-col>

          </v-row>

        </v-card-text>
        <v-divider />
        <v-card-actions class='grey lighten-4'>
          <v-spacer />
          <v-btn class='primary' @click='updatePackage'>Save</v-btn>
          <v-btn class='secondary' @click='editPackage.isOpen=false'>Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<style lang="scss" scoped>
//@import "@/assets/common";
.dragMe{
  cursor: move;
}
</style>

<script>
import Sortable from 'sortablejs'
import { mapActions } from 'vuex'
import LazyDropdown from '@/components/LazyDropdown'
export default {
  name: 'PackageTemplateManager',

  components: {
    LazyDropdown
  },

  data: () => ({
    isOpen: false,
    loading: true,
    packages: [],
    sortable: null,
    editPackage: {
      isOpen: false,
      loading: false,
      data: { },
    }
  }),

  computed: {
  },

  watch: {
    isOpen(isOpen){
      if(isOpen) { this.refresh(); }
      else this.couriers = [];
    },
  },

  methods:{
    ...mapActions('UI', ['PROMPT', 'CONFIRM']),
    ...mapActions('API', ['API']),

    open(){ this.isOpen = true; },
    close(){ this.isOpen = false; },

    refresh(){
      this.loading = true;
      return this.API({ method: 'GET', endpoint: 'dispatch/package_templates' })
      .then(packages => {
        this.packages = packages;
        this.makeSortable();
      })
      .finally(() => { this.loading = false; });
    },

    newPackage(){
      this.loading = true;
      let newPackage = null;
      this.API({ method: 'POST', endpoint: 'dispatch/package_templates/create' })
      .then(p => { newPackage = p })
      .then(this.refresh)
      .then(() => { this.clickEditPackage(newPackage); })
      .finally(() => { this.loading = false; });
    },

    clickEditPackage(p){
      console.log(p);
      this.editPackage.data = Object.assign({}, p);
      this.editPackage.isOpen = true;
      /*this.PROMPT({ title: 'Package Label', placeholder: p.label })
      .then(label => {
        this.loading = true;
        let data = { label };
        this.API({ method: 'PATCH', endpoint: `dispatch/package_templates/${p.xid}`, data })
        .then(this.refresh)
        .finally(() => { this.loading = false; });
      });*/
    },

    async deletePackage(p){
      await this.CONFIRM({ title: 'Delete Package', message: `Remove package template "${p.label}"?` });
      this.API({ method: 'DELETE', endpoint: `dispatch/package_templates/${p.xid}` })
      .then(this.refresh)
      .finally(() => { this.loading = false; });
    },

    makeSortable(){
      if(this.sortable) return;
      this.sortable = new Sortable(this.$refs.sortArea.$el, {
        animation: 250,
        handle: '.dragMe',
        swapThreshold: 1,
        onEnd: this.sortPackages,
      });
    },

    sortPackages(e){
      if(e.oldIndex == e.newIndex) return;
      let sort_order = this.sortable.toArray();
      this.loading = true;
      this.API({ method: 'POST', endpoint: 'dispatch/package_templates/sort', data: { sort_order } })
      .finally(() => { this.loading = false; });
    },

    updatePackage(){
      let data = {
        ...this.editPackage.data,
        courier: this.editPackage.data.courier?.xid ?? null
      };
      this.editPackage.loading = true;
      this.API({ method: 'PATCH', endpoint: `dispatch/package_templates/${data.xid}`, data })
      .then(() => {
        this.refresh();
        this.editPackage.isOpen = false;
      })
      .finally(() => { this.editPackage.loading = false; });
    }

  },

  mounted() {

  },

};
</script>
