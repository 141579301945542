var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-dialog', {
    attrs: {
      "max-width": "450",
      "scrollable": ""
    },
    model: {
      value: _vm.isOpen,
      callback: function callback($$v) {
        _vm.isOpen = $$v;
      },
      expression: "isOpen"
    }
  }, [_c('v-card', {
    attrs: {
      "loading": _vm.loading,
      "disabled": _vm.loading
    }
  }, [_c('v-card-title', {
    staticClass: "primary white--text"
  }, [_vm._v(" Package Templates "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "fab": "",
      "small": "",
      "text": "",
      "dark": ""
    },
    on: {
      "click": _vm.newPackage
    }
  }, [_c('v-icon', [_vm._v("mdi-plus")])], 1)], 1), _c('v-card-text', {
    staticClass: "px-0"
  }, [_c('v-list', {
    ref: "sortArea",
    attrs: {
      "dense": ""
    }
  }, _vm._l(_vm.packages, function (p) {
    return _c('v-list-item', {
      key: p.xid,
      attrs: {
        "data-id": p.xid
      }
    }, [_c('v-list-item-avatar', {
      staticClass: "dragMe ma-0"
    }, [_c('v-icon', {}, [_vm._v("mdi-drag")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(p.label))]), p.courier ? _c('v-list-item-subtitle', [_vm._v(_vm._s(p.courier.label))]) : _vm._e()], 1), _c('v-list-item-action', {
      staticClass: "ma-0"
    }, [_c('v-menu', {
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref) {
          var on = _ref.on,
            attrs = _ref.attrs;
          return [_c('v-btn', _vm._g(_vm._b({
            staticClass: "primary--text",
            attrs: {
              "fab": "",
              "x-small": "",
              "text": ""
            }
          }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-dots-horizontal")])], 1)];
        }
      }], null, true)
    }, [_c('v-list', [_c('v-list-item', {
      attrs: {
        "link": ""
      },
      on: {
        "click": function click($event) {
          return _vm.clickEditPackage(p);
        }
      }
    }, [_c('v-icon', {
      staticClass: "primary--text mr-4"
    }, [_vm._v("mdi-pencil")]), _vm._v("Edit")], 1), _c('v-divider'), _c('v-list-item', {
      attrs: {
        "link": ""
      },
      on: {
        "click": function click($event) {
          return _vm.deletePackage(p);
        }
      }
    }, [_c('v-icon', {
      staticClass: "error--text mr-4"
    }, [_vm._v("mdi-trash-can")]), _vm._v("Remove")], 1)], 1)], 1)], 1)], 1);
  }), 1)], 1), _c('v-divider'), _c('v-card-actions', {
    staticClass: "grey lighten-4"
  }, [_c('v-spacer'), _c('v-btn', {
    staticClass: "secondary",
    on: {
      "click": _vm.close
    }
  }, [_vm._v("Close")])], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "max-width": "600",
      "scrollable": "",
      "persistent": ""
    },
    model: {
      value: _vm.editPackage.isOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.editPackage, "isOpen", $$v);
      },
      expression: "editPackage.isOpen"
    }
  }, [_vm.editPackage.isOpen ? _c('v-card', {
    attrs: {
      "loading": _vm.loading,
      "disabled": _vm.editPackage.loading
    }
  }, [_c('v-card-title', {
    staticClass: "primary white--text"
  }, [_vm._v(" Edit Package Template ")]), _c('v-card-text', {
    staticClass: "pt-4",
    staticStyle: {
      "max-hxeight": "100vh",
      "heifght": "auto"
    }
  }, [_c('v-row', {}, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "Template Label"
    },
    model: {
      value: _vm.editPackage.data.label,
      callback: function callback($$v) {
        _vm.$set(_vm.editPackage.data, "label", $$v);
      },
      expression: "editPackage.data.label"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "Description of Contents"
    },
    model: {
      value: _vm.editPackage.data.description,
      callback: function callback($$v) {
        _vm.$set(_vm.editPackage.data, "description", $$v);
      },
      expression: "editPackage.data.description"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "Length",
      "suffix": "cm"
    },
    model: {
      value: _vm.editPackage.data.length,
      callback: function callback($$v) {
        _vm.$set(_vm.editPackage.data, "length", $$v);
      },
      expression: "editPackage.data.length"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "Width",
      "suffix": "cm"
    },
    model: {
      value: _vm.editPackage.data.width,
      callback: function callback($$v) {
        _vm.$set(_vm.editPackage.data, "width", $$v);
      },
      expression: "editPackage.data.width"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "Height",
      "suffix": "cm"
    },
    model: {
      value: _vm.editPackage.data.height,
      callback: function callback($$v) {
        _vm.$set(_vm.editPackage.data, "height", $$v);
      },
      expression: "editPackage.data.height"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "Weight",
      "suffix": "kg"
    },
    model: {
      value: _vm.editPackage.data.weight,
      callback: function callback($$v) {
        _vm.$set(_vm.editPackage.data, "weight", $$v);
      },
      expression: "editPackage.data.weight"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('LazyDropdown', {
    attrs: {
      "url": "dispatch/couriers",
      "outlined": "",
      "dense": "",
      "label": "Courier",
      "itemText": "label",
      "itemValue": "xid"
    },
    model: {
      value: _vm.editPackage.data.courier,
      callback: function callback($$v) {
        _vm.$set(_vm.editPackage.data, "courier", $$v);
      },
      expression: "editPackage.data.courier"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('LazyDropdown', {
    attrs: {
      "outlined": "",
      "dense": "",
      "label": "Service",
      "itemText": "label",
      "itemValue": "xid"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', {
    staticClass: "grey lighten-4"
  }, [_c('v-spacer'), _c('v-btn', {
    staticClass: "primary",
    on: {
      "click": _vm.updatePackage
    }
  }, [_vm._v("Save")]), _c('v-btn', {
    staticClass: "secondary",
    on: {
      "click": function click($event) {
        _vm.editPackage.isOpen = false;
      }
    }
  }, [_vm._v("Cancel")])], 1)], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }