<template>
  <v-container fluid class='pt-0 overflow-y-auto qms_settings_page'>

    <v-card style='max-width:1200px;' :disabled='loading' :loading='loading'>
      <v-card-title class='primary white--text text-h6'>
        <v-icon class='mr-2' dark>mdi-truck</v-icon>Dispatch
      </v-card-title>
      <v-card-text>
        <v-list class='transparent'>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Couriers</v-list-item-title>
              <v-list-item-subtitle>
                Configure which delivery methods are available for dispatching goods.
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn class='primary' @click='()=>{$refs.courierManager.open()}'>Manage</v-btn>
              <CourierManager ref='courierManager' />
            </v-list-item-action>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Package Templates</v-list-item-title>
              <v-list-item-subtitle>
                Add package templates to speed up your dispatch process.
                <br /><span class='text-caption font-italic'>eg. Orthotic Satchel, Shoe-Box</span>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn class='primary' @click='()=>{$refs.packageTemplateManager.open()}'>Manage</v-btn>
              <PackageTemplateManager ref='packageTemplateManager' />
            </v-list-item-action>
          </v-list-item>

        </v-list>
      </v-card-text>
    </v-card>


  </v-container>
</template>

<style lang="scss">
//@import "@/assets/common";
</style>

<script>
import CourierManager from '@/components/Dispatch/CourierManager'
import PackageTemplateManager from '@/components/Dispatch/PackageTemplateManager'
//import { mapActions } from 'vuex'
export default {
  name: 'Dispatch',

  components: {
    CourierManager, PackageTemplateManager
  },

  data: () => ({
    loading: false,
  }),

  computed: {
  },

  methods:{
  },

  mounted() {
  },

};
</script>
