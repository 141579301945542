var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-dialog', {
    attrs: {
      "max-width": "450",
      "scrollable": ""
    },
    model: {
      value: _vm.isOpen,
      callback: function callback($$v) {
        _vm.isOpen = $$v;
      },
      expression: "isOpen"
    }
  }, [_c('v-card', {
    attrs: {
      "loading": _vm.loading,
      "disabled": _vm.loading
    }
  }, [_c('v-card-title', {
    staticClass: "primary white--text"
  }, [_vm._v(" Couriers "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "fab": "",
      "small": "",
      "text": "",
      "dark": ""
    },
    on: {
      "click": _vm.newCourier
    }
  }, [_c('v-icon', [_vm._v("mdi-plus")])], 1)], 1), _c('v-card-text', {
    staticClass: "px-0"
  }, [_c('v-list', {
    ref: "sortArea",
    attrs: {
      "dense": ""
    }
  }, _vm._l(_vm.couriers, function (c) {
    return _c('v-list-item', {
      key: c.xid,
      attrs: {
        "data-id": c.xid
      }
    }, [_c('v-list-item-avatar', {
      staticClass: "dragMe ma-0"
    }, [_c('v-icon', {}, [_vm._v("mdi-drag")])], 1), _c('v-list-item-title', [_vm._v(" " + _vm._s(c.label) + " ")]), _c('v-list-item-action', {
      staticClass: "ma-0"
    }, [_c('v-menu', {
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref) {
          var on = _ref.on,
            attrs = _ref.attrs;
          return [_c('v-btn', _vm._g(_vm._b({
            staticClass: "primary--text",
            attrs: {
              "fab": "",
              "x-small": "",
              "text": ""
            }
          }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-dots-horizontal")])], 1)];
        }
      }], null, true)
    }, [_c('v-list', [_c('v-list-item', {
      attrs: {
        "link": ""
      },
      on: {
        "click": function click($event) {
          return _vm.editCourier(c);
        }
      }
    }, [_c('v-icon', {
      staticClass: "primary--text mr-4"
    }, [_vm._v("mdi-pencil")]), _vm._v("Edit")], 1), _c('v-divider'), _c('v-list-item', {
      attrs: {
        "link": ""
      },
      on: {
        "click": function click($event) {
          return _vm.deleteCourier(c);
        }
      }
    }, [_c('v-icon', {
      staticClass: "error--text mr-4"
    }, [_vm._v("mdi-trash-can")]), _vm._v("Remove")], 1)], 1)], 1)], 1)], 1);
  }), 1)], 1), _c('v-divider'), _c('v-card-actions', {
    staticClass: "grey lighten-4"
  }, [_c('v-spacer'), _c('v-btn', {
    staticClass: "secondary",
    on: {
      "click": _vm.close
    }
  }, [_vm._v("Close")])], 1)], 1)], 1), _vm.edit.courier ? _c('v-dialog', {
    attrs: {
      "value": _vm.edit.courier,
      "max-width": "350"
    }
  }, [_c('v-card', {
    attrs: {
      "loading": _vm.edit.loading,
      "disabled": _vm.edit.loading
    }
  }, [_c('v-card-title', {
    staticClass: "primary white--text"
  }, [_vm._v(" Edit Courier ")]), _c('v-card-text', {}, [_c('v-row', {
    staticClass: "mt-4"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "Courier Name"
    },
    model: {
      value: _vm.edit.courier.label,
      callback: function callback($$v) {
        _vm.$set(_vm.edit.courier, "label", $$v);
      },
      expression: "edit.courier.label"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('LazyDropdown', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "Courier Tracking",
      "url": "dispatch/tracking_couriers",
      "item-text": "name_en",
      "item-value": "xid"
    },
    model: {
      value: _vm.edit.courier._17track_courier,
      callback: function callback($$v) {
        _vm.$set(_vm.edit.courier, "_17track_courier", $$v);
      },
      expression: "edit.courier._17track_courier"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', {
    staticClass: "grey lighten-4"
  }, [_c('v-spacer'), _c('v-btn', {
    staticClass: "primary",
    on: {
      "click": _vm.edit_save
    }
  }, [_vm._v("Save")]), _c('v-btn', {
    staticClass: "secondary",
    on: {
      "click": function click($event) {
        _vm.edit.courier = null;
      }
    }
  }, [_vm._v("Cancel")])], 1)], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }