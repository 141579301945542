<template>
  <div>

    <v-dialog v-model='isOpen' max-width="450" scrollable>
      <v-card :loading='loading' :disabled='loading'>
        <v-card-title class='primary white--text'>
          Couriers
          <v-spacer />
          <v-btn fab small text dark @click='newCourier'><v-icon>mdi-plus</v-icon></v-btn>
        </v-card-title>
        <v-card-text style='' class='px-0'>
          <v-list dense ref='sortArea'>
            <v-list-item v-for='c in couriers' :key='c.xid' :data-id="c.xid">
              <v-list-item-avatar class='dragMe ma-0'>
                <v-icon class=''>mdi-drag</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>
                {{c.label}}
              </v-list-item-title>
              <v-list-item-action class='ma-0'>
                <v-menu>
                  <template #activator="{on,attrs}">
                    <v-btn fab x-small text class='primary--text' v-on='on' v-bind="attrs">
                      <v-icon>mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item link @click='editCourier(c)'><v-icon class='primary--text mr-4'>mdi-pencil</v-icon>Edit</v-list-item>
                    <v-divider></v-divider>
                    <v-list-item link @click='deleteCourier(c)'><v-icon class='error--text mr-4'>mdi-trash-can</v-icon>Remove</v-list-item>
                  </v-list>
                </v-menu>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider />
        <v-card-actions class='grey lighten-4'>
          <v-spacer />
          <v-btn class='secondary' @click='close'>Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog :value='edit.courier' v-if='edit.courier' max-width='350'>
      <v-card :loading='edit.loading' :disabled='edit.loading'>
        <v-card-title class='primary white--text'>
          Edit Courier
        </v-card-title>
        <v-card-text style='' class=''>
          <v-row class='mt-4'>
            <v-col cols=12>
              <v-text-field outlined dense hide-details label='Courier Name' v-model='edit.courier.label' />
            </v-col>
            <v-col cols=12>
              <LazyDropdown outlined dense hide-details label='Courier Tracking' url='dispatch/tracking_couriers' v-model='edit.courier._17track_courier' item-text='name_en' item-value='xid' />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class='grey lighten-4'>
          <v-spacer />
          <v-btn class='primary' @click='edit_save'>Save</v-btn>
          <v-btn class='secondary' @click='edit.courier=null'>Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<style lang="scss" scoped>
//@import "@/assets/common";
.dragMe{
  cursor: move;
}
</style>

<script>
import Sortable from 'sortablejs'
import { mapActions } from 'vuex'
import LazyDropdown from '@/components/LazyDropdown'
export default {
  name: 'CourierManager',

  components: {
    LazyDropdown
  },

  data: () => ({
    isOpen: false,
    loading: true,
    couriers: [],
    sortable: null,
    edit:{
      loading: false,
      courier: null,
    }
  }),

  computed: {
  },

  watch: {
    isOpen(isOpen){
      if(isOpen) { this.refresh(); }
      else this.couriers = [];
    },
  },

  methods:{
    ...mapActions('UI', ['PROMPT', 'CONFIRM']),
    ...mapActions('API', ['API']),

    open(){ this.isOpen = true; },
    close(){ this.isOpen = false; },

    refresh(){
      this.loading = true;
      return this.API({ method: 'GET', endpoint: 'dispatch/couriers' })
      .then(couriers => {
        this.couriers = couriers;
        this.makeSortable();
      })
      .finally(() => { this.loading = false; });
    },

    newCourier(){
      this.loading = true;
      let newCourier = null;
      this.API({ method: 'POST', endpoint: 'dispatch/couriers/create' })
      .then(courier => { newCourier = courier })
      .then(this.refresh)
      .then(() => { this.editCourier(newCourier); })
      .finally(() => { this.loading = false; });
    },

    editCourier(courier){
      /*this.PROMPT({ title: 'Label Courier', placeholder: courier.label })
      .then(label => {
        this.loading = true;
        let data = { label };

      });
      */
      this.edit = { ...this.edit, courier, loading: false };
    },

    edit_save(){
      this.edit.loading = true;
      let data = {
        ...this.edit.courier,
        _17track_courier: this.edit.courier._17track_courier?.xid ?? null
      };
      this.API({ method: 'PATCH', endpoint: `dispatch/couriers/${this.edit.courier.xid}`, data })
      .then(() => {
        this.refresh();
        this.edit.courier = null;
      })
      .finally(() => { this.edit.loading = false; });
    },

    async deleteCourier(courier){
      await this.CONFIRM({ title: 'Delete Courier', message: `Remove courier "${courier.label}"?` });
      this.API({ method: 'DELETE', endpoint: `dispatch/couriers/${courier.xid}` })
      .then(this.refresh)
      .finally(() => { this.loading = false; });
    },

    makeSortable(){
      if(this.sortable) return;
      this.sortable = new Sortable(this.$refs.sortArea.$el, {
        animation: 250,
        handle: '.dragMe',
        swapThreshold: 1,
        onEnd: this.sortCouriers,
      });
    },

    sortCouriers(e){
      if(e.oldIndex == e.newIndex) return;
      let sort_order = this.sortable.toArray();
      this.loading = true;
      this.API({ method: 'POST', endpoint: 'dispatch/couriers/sort', data: { sort_order } })
      .finally(() => { this.loading = false; });
    },

  },

  mounted() {

  },

};
</script>
